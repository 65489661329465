import { expValEquals } from '@confluence/feature-experiments';

export const useIsAdvancedSearchInTreeEnabled = (): {
	isAdvancedSearchInTreeEnabled: boolean;
} => {
	return {
		isAdvancedSearchInTreeEnabled: expValEquals<'test' | 'control'>(
			'advanced_search_in_tree',
			'cohort',
			'test',
		),
	};
};
